.button {
	background-color: transparent;
	display: inline-block;
	border: 1px solid $red;
	color: $red;
	padding: 15px 30px;
	min-width: 170px;
	max-width: 100%;
	font-weight: 500;
	text-align: center;
	font-size: 22px;
	border-radius: 0 !important;
	&:hover {
		background-color: $red;
		color: white;
	}
	&.button-full {
		background-color: $red;
		color: white;
	}
	&.button-white {
		color: white;
		border-color: white;
	}
	@media(max-width: $md){
		font-size: 19px;
		padding: 15px 20px;
	}
	@media(max-width: $sm){
		min-width: 140px;
		font-size: 17px;
		padding: 15px 5px;
	}
	/*@media(max-width: $sm){
		font-size: 15px;
		padding: 10px 15px;
		margin-left: auto;
		margin-right: auto;
	}*/
}

.button,[type="button"] {
	-webkit-appearance: none;
}