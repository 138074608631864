body {
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    line-height: 1.2em;
    color: $black;
    overflow-x: hidden;
    @media(max-width: $md){
    	font-size: 18px;
    }
    @media(max-width: $sm){
    	font-size: 15px;
    }
}
p {
	margin-bottom: 1em;
	color: inherit;
}
a, a:hover, a:focus {
	color: inherit;
	text-decoration: none;
}
.container-small {
	padding-left: 300px;
	padding-right: 300px;
	@media(max-width: $lg){
		padding-left: 200px;
		padding-right: 200px;
	}
	@media(max-width: $md){
		padding-left: 100px;
		padding-right: 100px;
	}
	@media(max-width: $sm){
		padding-left: 50px;
		padding-right: 50px;
	}
	@media(max-width: $xs){
		padding-left: 35px;
		padding-right: 35px;
	}
}
.container-medium {
	padding-left: 100px;
	padding-right: 100px;
	@media(max-width: $sm){
		padding-left: 50px;
		padding-right: 50px;
	}
	@media(max-width: $xs){
		padding-left: 35px;
		padding-right: 35px;
	}
}
main {
	padding-bottom: 80px;
	@media(max-width: $md){
		padding-bottom: 60px;
	}
	@media(max-width: $sm){
		padding-bottom: 45px;
	}
}
.thumb {
	background-size: cover;
	background-position: center;
	width: 100%;
}
img {
	max-width: 100%;
}

.mobile, .cat-mobile { 
	display: none; 
}
@media(max-width: $cat-sm){
	.cat-mobile { display: block; }
	.cat-desktop { display: none !important; }
}
@media(max-width: $sm){
	.mobile { display: block; }
	.desktop { display: none !important; }
}

*,*:focus,*:active {
	outline: 0;
}



