$red: #A31C36;
$black: #55565A;
$gray: #9A9A9A;

$lg: 1560px;
$md: 1280px;
$sm: 768px;
$xs: 576px;

$menu: 1640px;
$breadcrumbs: 1100px;
$menuH: 900px;

$cat-sm: 1000px;
$news-home: 950px;