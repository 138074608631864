.block-stretch {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 50px;
	overflow: hidden;
	&:nth-child(even) {
		flex-direction: row-reverse;
		@media(max-width: $sm){
			flex-direction: column;
		}
	}
	.stretch-col {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
	}
	.stretch-content {
		padding-left: 60px;
		padding-right: 200px;
		font-weight: 400;
		align-items: flex-start;
		@media(max-width: $md){
			padding-right: 100px;
			padding-left: 16px;
		}
		@media(max-width: $sm){
			padding-right: 35px;
			padding-left: 35px;
		}
		h2 {
			margin-bottom: 50px;
			@media(max-width: $md){
				margin-bottom: 30px;
			}
			@media(max-width: $sm){
				margin-bottom: 20px;
			}
		}

	}
	&:nth-child(even) {
		.stretch-content {
			padding-right: 60px;
			padding-left: 200px;
			text-align: right;
			align-items: flex-end;
			@media(max-width: $md){
				padding-left: 100px;
				padding-right: 16px;
			}
			@media(max-width: $sm){
				padding-left: 35px;
				padding-right: 35px;
			}
		}
	}
	.mobile { 
		.thumb {
			width: 80%;
			padding-bottom: 25%;
			display: inline-block;
			margin-bottom: 20px;
			@media(max-width: $sm){
				width: 90%;
			}
		}
		img {
			vertical-align: top;
			@media(max-width: $sm){
				width: 30px;
			}
		}
	}
	&:nth-child(even) {
		.mobile { 
			.thumb {
				margin-right: -60px;
			}
			img {
				margin-right: 20px;
				float: left;
				@media(max-width: $sm){
					margin-right: 10px;
				}
			}
		}
	}
	&:nth-child(odd) {
		.mobile { 
			.thumb {
				margin-left: -60px;
			}
			img {
				margin-left: 20px;
				@media(max-width: $sm){
					margin-left: 10px;
				}
			}
		}
	}

	@media(max-width: $sm){
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.block-no-stretch {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 100px;
	@extend .container-small;
	&:nth-child(even) {
		flex-direction: row-reverse;
	}
	.no-stretch-col {
		display: flex;
		flex-direction: column;
		flex-basis: 100%;
		flex: 1;
	}
	.no-stretch-content {
		padding-left: 60px;
		font-weight: 400;
		align-items: flex-start;
		h2 {
			margin-bottom: 50px;
		}

	}
	&:nth-child(even) {
		.no-stretch-content {
			padding-right: 60px;
			text-align: right;
			align-items: flex-end;
		}
	}
	.mobile { 
		.thumb {
			width: 80%;
			padding-bottom: 25%;
			display: inline-block;
			margin-bottom: 20px;
			@media(max-width: $sm){
				width: 90%;
			}
		}
		img {
			vertical-align: top;
			@media(max-width: $sm){
				width: 30px;
			}
		}
	}
	&:nth-child(even) {
		.mobile { 
			.thumb {
				margin-right: -110px;
			}
			img {
				margin-right: 20px;
				@media(max-width: $sm){
					margin-right: 10px;
				}
			}
		}
	}
	&:nth-child(odd) {
		.mobile { 
			.thumb {
				margin-left: -110px;
			}
			img {
				margin-left: 20px;
				@media(max-width: $sm){
					margin-left: 10px;
				}
			}
		}
	}
}

.shadow-block {
	box-shadow: 3px 3px 10px #00000029;
	padding: 45px;
	@media(max-width: $md){
		padding: 45px;
	}
	@media(max-width: $sm){
		padding: 15px;
	}
}


.stretch-thumb {
	@extend .thumb;
}