form {
	input[type="text"],input[type="email"],input[type="tel"], select, textarea,
	.select2 {
		padding: 15px 25px;
		border: 1px solid #55565A;
		border-radius: 4px;
		font-size: 23px;
		line-height: 28px;
		margin-bottom: 30px;
		width: 100%;
		max-width: 100%;
		@media(max-width: $md){
			font-size: 18px;
			line-height: 20px;
		}
		@media(max-width: $sm){
			font-size: 15px;
			line-height: 18px;
			margin-bottom: 20px;
			padding: 10px 15px;
		}
	}
	textarea {
		height: 300px;
		@media(max-width: $sm){
			height: 170px;
		}
	}
}
.select2-container--default .select2-selection--single {
	border: 0;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: 26px;
    position: absolute;
    top: 23px;
    right: 15px;
    width: 30px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #00000099 transparent transparent
    transparent;
    border-style: solid;
    border-width: 15px 15px 0 15px;
    height: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    top: 0;
    width: 0;
}
.select2-results__option {
	font-size: 14px;
	line-height: 30px;
	border-bottom: 1px solid #D9D9DA;
}
.fake-checkbox {
	display: inline-block;
	height: 15px;
	width: 15px;
	border: 1px solid black;
	border-radius: 4px;
	margin-right: 5px;
}
input:checked + .fake-checkbox:after {
	content: '\d7';
	position: absolute;
	line-height: 11px;
}
label input[type="checkbox"]{
	display: none;
}