#header-home {
	height: auto;
	z-index: 99999;
	position: absolute;
	width: 100%;
}
#home {
	overflow-x: hidden;
	h2 {
		text-align: center;
	}
	.block-stretch, .block-no-stretch {
		margin: 120px 0 100px;
		@media(max-width: $lg){
			margin: 60px 0 50px;
		}
		@media(max-width: $sm){
			margin: 30px 0 25px;
		}
		h2 {
			text-align: left;
			margin-bottom: 30px;
			@media(max-width: $sm){
				text-align: center;
			}
		}
		.button {
			margin-bottom: 80px;
			margin-top: 20px;
			@media(max-width: $lg){
				margin-bottom: 40px;
			}
			@media(max-width: $sm){
				margin-bottom: 20px;
				width: 140px;
				margin-left: auto;
				margin-right: auto;
				display: block;
			}
		}
		&:nth-child(even) {
			h2 {
				text-align: right;
				@media(max-width: $sm){
					text-align: center;
				}
			}
		}
		.no-stretch-thumb {
			position: relative;
		}
		@media(max-width: $sm){
			&#block-3 {
				.no-stretch-content {
					text-align: right;
					padding: 0;
				}
				.mobile .thumb {
					margin-left: 0;
					margin-right: -110px;
					width: 100%;
				}
			}
			&#block-4 {
				.no-stretch-content {
					text-align: left;
					padding: 0;
				}
				.mobile .thumb {
					margin-right: 0;
					margin-left: -110px;
					width: 120%;
				}
			}
		}
	}
	#download-rsc {
		position: absolute;
		bottom: 30px;
		right: -60px;
		font-weight: 700;
	}
}
#download-rsc {
	padding: 12px 30px 18px;
	background: $red;
	color: white;
	font-size: 25px;
	img {
		vertical-align: bottom;
		width: 50px;
		position: relative;
		bottom: -2px;
	}
	@media(max-width: $lg){
		font-size: 24px;
		font-weight: 400;
	}
	@media(max-width: 900px){
		display: none;
	}
	
}
#slider-home {
	margin-top: -136px;
	height: 880px;
	max-height: 80vH;
	overflow: hidden;
	@media(max-width: $menu){
		height: 300px;
		margin-top: 0;
	}
	@media(max-width: $sm){
		height: 150px;
	}
	.slide {
		height: 880px;
		max-height: 80vH;
		position: relative;
		@media(max-width: $menu){
			height: 300px;
		}
		@media(max-width: $sm){
			height: 150px;
		}
		.container {
			height: 100%;
			position: relative;
		}
		h3 {
			position: absolute;
			left: 0;
			bottom: 50px;
			color: white;
			font-size: 40px;
			line-height: 48px;
			max-width: 500px;
			margin: 0;
			text-align: right;
			text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
			@media(max-width: $menu){
				font-size: 35px;
				line-height: 45px;
				bottom: 30px;
			}
			@media(max-width: $sm){
				font-size: 15px;
				line-height: 25px;
				text-align: center;
				width: 100%;
			}
		}
		#slider-layer-top {
			background: #3B3B3B 0% 0% no-repeat padding-box;
			opacity: 0.5;
			filter: blur(50px);
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 300px;
			@media(max-width: $menu){
				height: 100px;
			}
			@media(max-width: $xs){
				height: 50px;
			}
		}
		#slider-layer-bottom {
			background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 80px;
			opacity: 0.2;
			@media(max-width: $xs){
				display: none;
			}
		}
	}
}

#home-brands {
	margin: 50px 0 95px;
	#slider-brands {
		.slide-brand {
			width: 260px;
			height: 170px;
			position: relative;
			text-align: center;
			@media(max-width: $md){
				height: 85px;
			}
			.brand-product {
				opacity: 0.4;
				transition: opacity 0.4s;
				@media(max-width: $md){
					display: inline-block;
					height: 85px;
				}
			}
			.brand-logo {
				position: absolute;
				max-width: 195px;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				transition: opacity 0.2s;
				@media(max-width: $md){
					max-height: 25px;
				}
			}
			&:hover {
				.brand-logo { opacity: 0; }
				.brand-product { opacity: 1; }
			}
		}
		.slick-arrow {
			font-size: 50px;
			position: absolute;
			top: 50%;
			color: $red;
			font-weight: 700;
			cursor: pointer;
			width: 25px;
			height: 35px;
			&.arrow-left {
				left: 0;
				transform: translate(-100%,-50%);
				background-image: url('../img/arrow_home_brands.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
			&.arrow-right {
				right: 0;
				transform: translate(100%,-50%) rotate(180deg);
				background-image: url('../img/arrow_home_brands.svg');
				background-size: contain;
				background-repeat: no-repeat;
			}
		}
	}
}

#indications-content {
	display: flex;
	nav {
		a {
			display: block;
			border-left: 18px solid;
			padding-left: 10px;
			padding-right: 40px;
			white-space: nowrap;
			line-height: 60px;
			font-weight: 700;
			position: relative;
			&:hover {
				color: white;
			}
			@media(max-width: $md){
				line-height: 50px;
			}
			@media(max-width: $sm){
				color: white;
				font-size: 15px;
				line-height: 35px;
				padding-left: 37px;
				border: 0;
				color: white !important;
				&[data-color="#a31f35"]{
					background-color: #a31f35 !important;
				}
				&[data-color="#f38a00"]{
					background-color: #f38a00 !important;
				}
				&[data-color="#00945f"]{
					background-color: #00945f !important;
				}
				&[data-color="#9e1996"]{
					background-color: #9e1996 !important;
				}
				&[data-color="#004a87"]{
					background-color: #004a87 !important;
				}
				&[data-color="#008996"]{
					background-color: #008996 !important;
				}
				&[data-color="#e84658"]{
					background-color: #e84658 !important;
				}
				&[data-color="#6a368f"]{
					background-color: #6a368f !important;
				}
				&[data-color="#00a1e1"]{
					background-color: #00a1e1 !important;
				}
				&[data-color="#cc0081"]{
					background-color: #cc0081 !important;
				}
			}
			@media(max-width: $xs){
				white-space: normal;
				line-height: 20px;
				padding: 5px 35px;
			}
			span {
				position: absolute;
				right: 100%;
				width: 18px;
				text-align: center;
				padding: 3px 4px;
				height: 60px;
				transition: width 0.3s;
				@media(max-width: $md){
					height: 50px;
				}
				@media(max-width: $sm){
					display: none;
				}
				img {
					display: block;
					width: 0;
				}
			}
			&:hover, &.active {
				//background-color: #00A1E1;
				color: white;
				span {
					width: 62px;
					@media(max-width: $md){
						width: 50px;
					}
				}
				img {
					width: 54px;
					@media(max-width: $md){
						width: 40px;
					}
				}
			}
		}
	}
	#first-menu, .thumb {
		@media(max-width: $sm){
			width: 60%;
		}
	}
}

#idi-block {
	padding: 80px 30px;
	text-align: center;
	margin-bottom: 100px;
	@media(max-width: $lg){
		padding: 50px 0 60px;
		margin-bottom: 50px;
	}
	@media(max-width: $sm){
		padding: 30px;
		margin-bottom: 30px;
	}
	p {
		max-width: 750px;
		margin: 0 auto 40px;
		@media(max-width: $sm){
			margin: 0 auto 20px;
		}
	}
}

#home-news {
	display: flex;
	@media(max-width: $news-home){
		flex-direction: column;
	}
	h2 {
		float: left;
		padding-right: 40px;
		position: relative;
		z-index: 999;
		background: white;
	}
	#slider-news {
		width: calc(100% - 270px);
		float: left;
		@media(max-width: $md){
			width: calc(100% - 210px);
		}
		@media(max-width: $news-home){
			width: 100%;
		}
		h3 {
			font-size: 24px;
			line-height: 30px;
			color: black;
			margin-bottom: 25px;
			@media(max-width: $md){
				font-size: 22px;
				line-height: 27px;
			}
			@media(max-width: $sm){
				font-size: 15px;
				line-height: 20px;
			}
		}
		.slick-list {
			overflow: visible;
		}
		.slick-slide {
			transition: opacity 0.3s;
		}
		.slick-slide:not(.slick-active) {
			opacity: 0;
		}
		.slick-active + .slick-slide:not(.slick-active) {
			opacity: 0.2;
		}
		.excerpt {
			font-size: 20px;
			line-height: 24px;
			@media(max-width: $md){
				line-height: 20px;
				font-size: 16px;
			}
			@media(max-width: $sm){
				line-height: 20px;
				font-size: 15px;
			}
		}
		.button {
			font-size: 22px;
			@media(max-width: $md){
				font-size: 16px;
				line-height: 16px;
			}
			padding: 15px 40px;
			min-width: 0;
			margin-top: 14px;
		}
		.slick-arrow {
			font-size: 100px;
			position: absolute;
			top: 50%;
			color: $red;
			font-weight: 700;
			cursor: pointer;
			&.arrow-left {
				left: 0;
				transform: translate(-100%,-50%) rotate(180deg);
				width: 50px;
				height: 130px;
				background-image: url('../img/arrow_home_news.svg');
				background-size: contain;
				background-repeat: no-repeat;
				z-index: 999;
				background-position: center;
			}
			&.arrow-right {
				background-image: url('../img/arrow_home_news.svg');
				right: 0;
				transform: translate(100%,-50%);
				width: 50px;
				height: 130px;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center;
			}
			&.slick-disabled {
				display: none;

			}
		}
	}
}

#home-indications {
	padding-right: 0;
	@media(max-width: $sm){
		margin-left: -50px;
	}
	.thumb {
		display: flex;
	}

}

#indications-home-submenus {
	min-width: 40%;
	height: 100%;
	nav {
		display: none;
		a {
			color: white !important;
			border: 0;
			@media(max-width: $sm){
				font-size: 14px;
				line-height: 35px;
				padding-left: 20px;
			}
			@media(max-width: $xs){
				line-height: 20px;
			}
		}
	}
	@media(max-width: $sm){
		width: 100%;
	}
}
#indications-home-thumb {
	width: 60%;
	height: 100%;
	background-size: cover;
	background-position: center;

	@media(max-width: $sm){
		display: none;
	}
}