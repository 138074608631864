/* Webfont: Lato-Heavy */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Heavy.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Heavy.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Heavy.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Heavy.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Heavy.ttf') format('truetype');
    font-style: normal;
    font-weight: 900;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Bold */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Bold.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Bold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Semibold */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Semibold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Semibold.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Semibold.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Semibold.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Medium */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Medium.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Medium.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Medium.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Medium.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Regular */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}


/* Webfont: Lato-Light */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-Light.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-Light.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-Light.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-Light.ttf') format('truetype');
    font-style: normal;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}

/* Webfont: Lato-Light */@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato-LightItalic.eot'); /* IE9 Compat Modes */
    src: url('../fonts/Lato-LightItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/Lato-LightItalic.woff2') format('woff2'), /* Modern Browsers */
         url('../fonts/Lato-LightItalic.woff') format('woff'), /* Modern Browsers */
         url('../fonts/Lato-LightItalic.ttf') format('truetype');
    font-style: italic;
    font-weight: 300;
    text-rendering: optimizeLegibility;
}
