#timeline {
	overflow: hidden;
	padding-right: 0;
	display: flex;
	@media(max-width: $sm){
		flex-direction: column;
		padding-left: 0;
	}
}
#timeline-navigation {
	width: 300px;
	float: left;
	text-align: center;
	white-space: nowrap;
	@media(max-width: $lg){
		width: 150px;
	}
	@media(max-width: $sm){
		width: 100%;
		margin-bottom: 20px;
		overflow: auto;
	}
	span {
		display: block;
		font-size: 25px;
		line-height: 60px;
		color: $red;
		font-weight: 700;
		opacity: 0.4;
		transition: all 0.25s;
		cursor: pointer;
		@media(max-width: $sm){
			font-size: 12px;
			line-height: 20px;
			display: inline;
		}
		&.sibling-1 {
			font-size: 35px;
			opacity: 0.8;
			@media(max-width: $sm){
				font-size: 13px;
			}
		}
		&.sibling-2 {
			font-size: 30px;
			opacity: 0.6;
			@media(max-width: $sm){
				font-size: 15px;
			}
		}
		&.slick-current {
			opacity: 1;
			font-size: 45px;
			@media(max-width: $sm){
				font-size: 20px;
			}
		}
	}
}
#timeline-wrap {
	position: relative;
	width: calc(100% - 300px);
	float: left;
	position: relative;
	@media(max-width: $lg){
		width: calc(100% - 150px);
	}
	@media(max-width: $sm){
		width: 100%;
		overflow: hidden;
	}
	#blur-bottom {
		background: #FFFFFF 0% 0% no-repeat padding-box;
		opacity: 1;
		filter: blur(33px);
		height: 168px;
		width: 100%;
		position: absolute;
		bottom: -92px;
		left: 0;
	}
}
#timeline-slider {
	//height: 100%;
	//position: relative;
	&:after {
		content: " ";
		width: 1px;
		height: 100%;
		border-right: 3px dotted $red;
		position: absolute;
		top: 0;
		left: 200px;
		z-index: -1;
		@media(max-width: $lg){
			left: 150px;
		}
		@media(max-width: $md){
			left: 100px;
		}
		@media(max-width: $sm){
			left: 40px;
		}
	}
	&:before {
		content: ' ';
		width: 100%;
		height: 50px;
		background-color: white;
		display: block;
		position: absolute;
		background: 0% 0% #FFFFFF no-repeat padding-box;
		opacity: 1;
		filter: blur(33px);
		height: 168px;
		width: 100%;
		position: absolute;
		left: 0;
		top: -92px;
		z-index: 1;
	}
	h2 {
		margin-top: 20px;
		font-size: 35px;
		@media(max-width: $md){
			font-size: 25px;
		}
		@media(max-width: $sm){
			font-size: 20px;
		}
	}
	img {
		max-width: 70%;
		margin-bottom: 30px;
		max-height: 300px;
	}
	.slick-arrow {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		z-index: 99;
		width: 40px;
		height: 25px;
		background-size: contain;
		background-repeat: no-repeat;
		cursor: pointer;
		&.arrow-top {
			top: 0;
			background-image: url('../img/arrow_timeline.svg');
			position: relative;
		}
		&.arrow-bottom {
			transform: rotate(180deg);
			bottom: 0;
			background-image: url('../img/arrow_timeline.svg');
		}
	}
}
.year {
	padding-bottom: 70px;
	min-height: 300px;
	.year-number {
		width: 400px;
		font-size: 100px;
		line-height: 80px;
		font-weight: 700;
		letter-spacing: 10px;
		color: $red;
		float: left;
		text-align: center;
		padding: 20px;
		background-color: white;
		@media(max-width: $lg){
			font-size: 70px;
			line-height: 60px;
			width: 300px;
		}
		@media(max-width: $md){
			font-size: 50px;
			line-height: 40px;
			width: 200px;
			letter-spacing: 5px;
			padding: 15px;
		}
		@media(max-width: $sm){
			width: 80px;
			font-size: 20px;
			line-height: 20px;
			letter-spacing: 1px;
			padding: 10px 0;
		}
	}
	.year-content {
		width: calc(100% - 400px);
		float: left;
		@media(max-width: $lg){
			width: calc(100% - 300px);
		}
		@media(max-width: $md){
			width: calc(100% - 200px);
		}
		@media(max-width: $sm){
			width: calc(100% - 80px);
		}
		h2, p {
			padding-right: 40px;
			@media(max-width: $sm){
				padding-right: 20px;
			}
		}
	}
}