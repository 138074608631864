#noticia-single {
	.date {
		color: $red;
		margin-bottom: 40px;
		@media(max-width: $sm){
			margin-bottom: 10px;
		}
	}
	h2 {
		font-size: 20px;
		line-height: 27px; 
		margin: 0;
		@media(max-width: $md){
			font-size: 18px;
			line-height: 24px;
		}
		@media(max-width: $sm){
			font-size: 15px;
			line-height: 24px;
		}
	}
}

#related-products {
	margin-top: 90px;
	h2 {
		margin-bottom: 50px;
		font-size: 22px;
		line-height: 24px;
	}
	.related-product {
		color: $red;
		margin-bottom: 80px;
		@media(max-width: $md){
			margin-bottom: 40px;
		}
		img {
			width: 100%;
			margin-bottom: 35px;
		}
	}
}