h1,.h1 {
	color: $red;
	font-size: 45px;
	line-height: 50px;
	font-weight: 700;
	margin-bottom: 50px;
	@media(max-width: $md){
		font-size: 35px;
		line-height: 45px;
		margin-bottom: 50px;
	}
	@media(max-width: $sm){
		font-size: 25px;
		line-height: 35px;
	}
	@media(max-width: $xs){
		font-size: 20px;
		line-height: 30px;
		text-align: center;
	}
}
h2, .h2 {
	color: $red;
	font-size: 45px;
	font-weight: 700;
	margin-bottom: 30px;
	@media(max-width: $lg){
		font-size: 40px;
	}
	@media(max-width: $md){
		font-size: 30px;
		margin-bottom: 20px;
	}
	@media(max-width: $sm){
		font-size: 20px;
		margin-bottom: 10px;
		text-align: left;
	}
	&.small {
		font-size: 25px;
		line-height: 30px;
		font-weight: 700;
	}
}
h2.h1 {
	margin-bottom: 30px;
}
h3, .h3 {
	font-size: 22px;
	line-height: 25px;
	font-weight: 700;
	color: $red;
	margin-bottom: 20px;
	@media(max-width: $md){
		font-size: 20px;
		line-height: 20px;
	}
	@media(max-width: $sm){
		font-size: 15px;
		line-height: 15px;
	}
}
h4 {
	font-size: 20px;
	line-height: 23px;
	font-weight: 700;
	@media(max-width: $md){
		font-size: 15px;
		line-height: 15px;
	}
}
.highlight {
	color: $red;
	//font-size: 1.1em;
}