.slick-dots {
	position: absolute;
	left: 50%;
	bottom: 25px;
	transform: translateX(-50%);
	margin: 0;
	padding: 0;
	@media(max-width: $menu){
		bottom: 15px;
	}
	@media(max-width: $sm){
		bottom: 5px;
	}
	li {
		display: inline-block;
		button {
			background-color: transparent;
			border: 1px solid white;
			border-radius: 50%;
			width: 15px;
			height: 15px;
			margin: 0 10px;
			font-size: 0;
			padding: 0;
			@media(max-width: $md){
				width: 5px;
				height: 5px;
				margin: 0 5px;
			}
		}
		&.slick-active button {
			background-color: white;
		}
	}
}
.slick-arrow.slick-disabled {
	display: none !important;
}