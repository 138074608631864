@import "fonts";
@import "variables";

@import "base";
@import "typography";

@import "partials/header";
@import "partials/footer";
@import "partials/category-menu";

@import "pages/home";
@import "pages/pages";
@import "pages/mission";
@import "pages/innovation";
@import "pages/contacto";
@import "pages/industrial";
@import "pages/compromiso";
@import "pages/proyectos";
@import "pages/news";
@import "pages/timeline";
@import "pages/marcas";

@import "singles/news";
@import "singles/marca";
@import "singles/category";
@import "singles/producto";

@import "components/buttons";
@import "components/forms";
@import "components/media_blocks";
@import "components/page_blocks";
@import "components/slider";
@import "components/modal";

@import "hamburgers/hamburgers";
@import "hamburgers/base";
@import "hamburgers/squeeze";