#category-aside, .category-aside {
	padding-left: 100px;
	width: 30%;
	margin-bottom: 50px;
	@media(max-width: $cat-sm){
		width: 100%;
		padding-right: 10px;
		padding-left: 10px;
		margin-bottom: 50px;
	}
	@media(max-width: $sm){
		margin-bottom: 20px;
	}

	#category-title {
		padding: 10px 15px;
		color: white;
		margin-bottom: 30px;
		font-size: 1.2em;
		display: flex;
		align-items: center;
		img {
			width: 50px;
			margin-right: 15px;
			vertical-align: -15px;
		}
		span {
			flex-grow: 1;
		}
		@media(max-width: $cat-sm){
			padding: 10px 0;
			margin-bottom: 0;
			font-weight: 700;
			img {
				width: 20px;
				vertical-align: middle;
				margin-right: 5px;
			}
		}
		.cat-menu-arrow {
			margin-top: 5px;
		}
		&.open {
			.cat-menu-arrow {
				transform: rotate(180deg);
			}
		}
	}

	#category-menu {
		border-left: 3px solid;
		@media(max-width: $cat-sm){
			display: none;
			padding-bottom: 20px;
			position: absolute;
			left: 0;
			width: 100%;
		}
		a {
			display: block;
			padding: 15px;
			transition: all 0.2s;
			font-weight: 400;
			&.active, &:hover {
				border-left: 6px solid;
				padding-left: 12px;
				font-weight: 700;
			}
			@media(max-width: $cat-sm){
				color: white;
				border-left: 2px solid white;
				margin: 0 20px;
			}
			@media(max-width: $sm){
				padding: 10px;
			}
		}
	}
}

#category-main,.category-main {
	padding-right: 100px;
	width: 70%;
	padding-left: 30px;
	@media(max-width: $cat-sm){
		width: 100%;
		padding-left: 100px;
	}
	@media(max-width: $sm){
		padding-right: 50px;
		padding-left: 50px;
	}
	@media(max-width: $xs){
		padding-right: 35px;
		padding-left: 35px;
	}
	h1 {
		text-align: left;
	}
}
