#compromiso {
	#download-rsc {
		margin: 20px 0 80px;
		display: inline-block;
		img {
			width: 40px;
		}
		@media(max-width: $md){
			margin: 30px 0;
			padding: 10px 15px;
		}
		@media(max-width: $sm){
			font-size: 19px;
			line-height: 35px;
			width: 100%;
			text-align: center;
			margin: 10px 0 30px;
			img {
				width: 22px;
				vertical-align: top;
			}
		}
	}
}
.compromise-text {
	text-align: center;
	b {
		color: $red;
	}
	p + p {
		margin-top: 75px;
		@media(max-width: $md){
			margin-top: 40px;
		}
	}
}

#compromise-images {
	text-align: center;
	margin-top: 90px;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media(max-width: $md){
		margin-top: 40px;
	}
	@media(max-width: $sm){
		justify-content: center;
		//display: block;

	}
	img {
		flex-basis: 150px;
		width: 150px;
		@media(max-width: $md){
			flex-basis: 100px;
			width: 100px;
		}
		@media(max-width: $sm){
			flex-basis: 80px;
			width: 80px;
			margin: 10px;
		}
	}
}

#compromiso {
	.image-block {
		background-position: bottom;
		transition: all 1s cubic-bezier(.23,1,.32,1);
		height: 50px;
		margin-top: 550px;
		@media(max-width: 1000px){
			margin-top: 430px;
		}
		@media(max-width: $sm){
			margin-top: 60px;
		}
		&.visible {
			height: 400px;
			margin-top: 0px;
			@media(max-width: 1000px){
				height: 300px;
			}
			@media(max-width: $sm){
				height: 110px;
			}
		}
	}
}
//-webkit-animation: clip 1.6s cubic-bezier(.23,1,.32,1) forwards;
//animation: clip 1.6s cubic-bezier(.23,1,.32,1) forwards;