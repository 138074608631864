.video-block {
	position: relative;
	height: 480px;
	background-size: cover;
	@media(max-width: $md){
		height: 400px;
	}
	@media(max-width: $sm){
		height: 300px;
	}
	#play {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		width: 135px;
		height: 135px;
		border-radius: 50%;
		background-image: url('../img/play.svg');
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}
	.video-block-top {
		position: absolute;
		height: 80px;
		width: 100%;
		top: 0;
		left: 0;
		background: transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box;
		opacity: 0.2;
		transform: matrix(-1, 0, 0, -1, 0, 0);
	}
	.video-block-bottom {
		position: absolute;
		height: 80px;
		width: 100%;
		bottom: 0;
		left: 0;
		background: transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box;
		opacity: 0.4;
	}
	iframe {
		width: 100%;
		height: 0;
		position: absolute;
		left: 0;
		bottom: 0;
		transition: height 0.6s;
	}
	&.on {
		height: 56.25vW;
		transition: height 0.6s;
		background-image: none;
		.video-block-top, .video-block-bottom {
			display: none;
		}
		iframe {
			position: relative;
			height: 56.25vW;
		}
	}
}

.image-block {
	height: 480px;
	margin: 50px 0 100px;
	@extend .thumb;
	@media(max-width: $md){
		height: 300px;
		margin: 30px 0 70px;
	}
	@media(max-width: $sm){
		height: 110px;
		margin: 30px 0;
	}
}