#proyectos {
	padding-bottom: 0;
	#download-rsc {
		display: inline-block;
		img {
			width: 40px;
		}
	}
}
#projects {
	background-color: rgba(0,0,0,0.7);
	background-blend-mode: multiply;
	background-size: cover;
	background-position: center;
	margin-top: 100px;
	position: relative;
	@media(max-width: $md){
		margin-top: 50px;
	}
	#projects-navigation {
		background-color: $red;
		color: white;
		display: flex;
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		@media(min-width: $md){
			font-size: 25px;
		}
		span {
			cursor: pointer;
		}
		img {
			height: 15px;
			width: auto;
		}
		.next img {
			transform: rotate(180deg);
		}
		@media(max-width: $breadcrumbs){
			padding: 0;
			font-size: 0;
			.prev {
				position: absolute;
				left: 10px;
				top: 50%;
			}
			.next {
				position: absolute;
				right: 10px;
				top: 50%;
			}
		}
	}
	#projects-content {
		padding-top: 40px;
		padding-bottom: 100px;
		padding-right: 0;
		display: flex;
		@media(max-width: 1400px){
			padding-left: 15px;
		}
		@media(max-width: $breadcrumbs){
			flex-direction: column;
			padding-bottom: 50px;
			.button {
				display: block;
			}
		}
		@media(max-width: $sm){
			.button {
				width: 140px;
				margin-left: auto;
				margin-right: auto;
			}
		}
		#projects-wrap {
			width: 35%;
			color: white;
			&:not(.slick-initialized){
				opacity: 0;
				max-height: 300px;
			}
			@media(max-width: $breadcrumbs){
				width: 100%;
			}
			h2 {
				color: white;
				@media(max-width: $md){
					text-align: left;
				}
			}
			i {
				margin-right: 10px;
			}
			img, .button {
				margin-top: 50px;
				@media(max-width: $sm){
					margin-top: 25px;
				}
			}
			img {
				display: block;
			}
		}
	}
}
#projects-map {
	width: 65%;
	position: relative;
	@media(max-width: $breadcrumbs){
		width: 100%;
		margin-top: 20px;
		height: 400px;
	}
	@media(max-width: $breadcrumbs){
		height: auto;
	}
	[data-ind]{
		cursor: pointer;
	}
	/*#project-1,#project-2,#project-3 {
		width: 30px;
		height: 30px;
		background-color: $red;
		position: absolute;
		cursor: pointer;
	}
	#project-1 {top: 20%; left: 30%; }
	#project-2 {top: 50%; left: 60%; }
	#project-3 {top: 80%; left: 50%; }*/
}