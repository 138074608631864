#industrial-blocks {
	.stretch-content {
		padding-bottom: 100px;
		@media(max-width: $md){
			padding-bottom: 50px;
		}
		@media(max-width: $sm){
			padding-bottom: 25px;
		}
	}
	.block-stretch {
		@media(max-width: $md){
			margin-bottom: 0px;
		}
	}
}

#certifications {
	text-align: center;
	margin-top: 80px;
	margin-bottom: 40px;
	padding: 0 35px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	@media(max-width: $md){
		margin-top: 50px;
		margin-bottom: 30px;
	}
	@media(max-width: $sm){
		flex-direction: column;
		h2 {
			text-align: left;
		}
	}
	.shadow-block {
		display: inline-block;
		width: 100%;
		max-width: 530px;
		margin-right: 15px;
		margin-bottom: 15px;
		@media(max-width: $md){
			padding-left: 20px;
			padding-right: 20px;
			max-width: 480px;
		}
		@media(max-width: 1000px){
			width: 50%;
		}
		@media(max-width: $sm){
			width: 100%;
			max-width: 100%;
		}
		img {
			height: 130px;
			width: auto;
			margin-bottom: 40px;
			@media(max-width: $sm){
				height: 80px;
				width: auto;
				margin-bottom: 20px;
			}
		}
		p {
			text-align: left;
		}
	}
}