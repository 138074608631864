#producto-single {
	@media(max-width: $breadcrumbs){
		margin-top: 30px;
	}
	@media(max-width: $cat-sm){
		margin-top: 0;
	}
	main img {
		margin: 30px 0 50px;
		@media(max-width: $md){
			margin: 15px 0;
		}
	}
	.button {
		margin-top: 50px;
		@media(max-width: $md){
			margin-top: 20px;
		}
		@media(max-width: $sm){
			background-color: $red;
			color: white !important;
		}
		img {
			width: 40px;
			@media(max-width: $md){
				width: 25px;
				margin-top: -2px;
			}
			@media(max-width: $sm){
				width: 15px;
			}
		}
	}
}

#product-principal, .product-principal {
	margin-bottom: 40px;
	i {
		font-weight: 300;
	}
	@media(max-width: $sm){
		.button {
			margin-left: auto;
			margin-right: auto;
			width: 140px;
			display: block;
		}
	}
}
#product-alergies {
	margin-top: 60px;
	@media(max-width: $sm){
		text-align: center;
	}
	.alergy {
		width: 120px;
		display: inline-block;
		font: 18px/22px;
		font-weight: 300;
		color: $red;
		text-align: center;
		vertical-align: top;
		margin-bottom: 15px;
		img {
			width: 75px;
			margin-bottom: 10px;
		}
	}
}
#product-article {
	padding-top: 40px;
	padding-bottom: 50px;
	margin-top: 40px;
	border-top: 2px solid;
	.button {
		margin-right: 20px;

	}
	.image-block {
		height: 250px;
		margin-top: 0;
		margin-bottom: 50px;
		@media(max-width: $sm){
			height: 150px;
			margin-bottom: 30px;
		}
	}
}
#product-article {
	h2.h3 {
		@media(max-width: $sm){
			font-size: 25px;
			line-height: 30px;
		}
	}
}

.product-single.modal-open * {
    filter: blur(5px);
}
.product-single.modal-open .modal, .modal-open .modal * {
    filter: blur(0);
}
#product-modal {
	svg {
		width: 230px;
		margin: 0 auto 80px;
		display: block;
		#name * {
			fill: #606060;
		}
	}
	@media(max-width: $sm){
		.button {
			background-color: $red;
			color: white;
		}
	}
}