#actualidad-page {

}

.news-list {
	.news-thumb {
		padding-bottom: 150%;
		margin-bottom: 24px;
		@extend .thumb;
	}
	h3 {font-weight: 500;}
	.button {
		min-width: 0;
		@media(max-width: $sm){
			width: 100%;
		}
	}
	margin-bottom: 55px;
}

#ajax-loader {
	margin-top: 150px;
	text-align: center;
	@media(max-width: $md){
		margin-top: 50px;
	}
	@media(max-width: $sm){
		margin-top: 0px;
	}
}