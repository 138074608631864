.category-container {
	display: flex;
	@media(max-width: $cat-sm){
		flex-direction: column;	
	}
}
.marca {
	display: flex;
	flex-direction: row;
	margin-bottom: 50px;
	@media(max-width: $sm){
		margin-bottom: 25px;
	}
	.marca-thumb {
		width: 20%;
		@extend .thumb;
	}
	.marca-content {
		flex-grow: 1;
		h2 {
			font-size: 30px;
			line-height: 30px;
			color: white;
			padding: 13px 36px;
			margin: 0;
		}
		.logos {
			padding: 30px 40px;
			.col-sm-6 {
				padding-right: 25px;
				padding-left: 25px;
			}
		}
		@media(max-width: $sm){
			.marca-title-wrap {
				display: flex;
				.marca-thumb, h2 {
					width: 50%;
				}
				h2 {
					padding: 28px 5px;
					text-align: center;
				}
			}
		}
		@media(max-width: $sm){
			h2 {
				font-size: 15px;
				line-height: 20px;
			}
		}
	}
}

.marca-logo {
	height: 80px;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	margin-bottom: 20px;
}

@media(max-width: $md){
	#marcas {
		> .container-medium {
			padding: 0;
		}
		.logos {
			padding-left: 35px;
			padding-right: 35px;
			padding-bottom: 0;
		}
	}
}