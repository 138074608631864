#subheader {
	padding-top: 5px;
	padding-bottom: 5px;
	background-color: $red;
	color: white;
	position: relative;
	z-index: 999999;
	@media(max-width: $menuH){
		display: none;
	}
	.container-medium {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		@media(max-width: 1700px){
			padding-right: 40px;
			padding-left: 40px;
		}
	}
	#social-subheader a {
		display: inline-block;
		border-radius: 50%;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 12px;
		border: 1px solid white;
		margin-right: 15px;
		i {
			vertical-align: text-top;
		}
	}
	#search {
		display: inline-block;
		cursor: pointer;
		form {
			display: inline-block;
		}
		input {
			display: none;
			width: 0;
			border-radius: 0;
			border: 0;
			padding: 0 10px;
			font-size: 15px;
			width: 200px;
			margin: 0;
		}
	}
	#lang {
		display: inline-block;
		position: relative;
		margin-left: 40px;
		img {
			cursor: pointer;
		}
		nav {
			display: none;
			background-color: white;
			top: 40px;
			right: 0;
			position: absolute;
			color: $red;
			font-size: 15px;
			padding: 5px 15px;
			z-index: 99;
			a {
				padding: 5px;
				text-align: left;
				border-left: 3px solid $red;
				&.active {
					border-left: 5px solid $red;
					font-weight: 700;
				}
			}
		}
	}
}

header {
	background-size: cover;
	background-position: center;
	height: 320px;
	//height: 0;
	//padding-bottom: 22%;
	position: relative;
	/*@media(max-width: $md){
		padding-bottom: 20%;
		height: 0;
	}
	@media(max-width: $sm){
		padding-bottom: 25%;
	}*/
	@media(max-width: $menu){
		height: 300px;
	}
	@media(max-width: $sm){
		height: 150px;
	}
	@media(max-width: $breadcrumbs){
		//height: 300px;
		margin-bottom: 20px;
		&.no-margin {
			margin-bottom: 0;
		}
	}
	@media(max-width: $sm){
		height: 150px;
	}
	.container-header .logo {
		position: relative;
		z-index: 9;
		height: 44px;
		width: auto;
		margin-left: 15px;
		@media(max-width: $menu){
			height: 32px;
		}
		@media(max-width: $md){
			height: 26px;
		}
		@media(max-width: $sm){
			height: 24px;
		}
	}
	.container-header:hover .logo {
		#name * {
			fill: #55565A !important;
		}
	}
	> a .logo {
		display: none;
		position: absolute;
		z-index: 99999;
		height: 24px;
		width: auto;
		top: 25px;
		left: 25px;
		@media(max-width: $menuH){
			display: block;
		}
		@media(max-width: $sm){
			height: 25px;
		}
	}
	&.menu-out > a .logo {
		.st0{
			fill:#FFF !important;
		}
	}
	
	#header-layer-top {
		background: #3B3B3B 0% 0% no-repeat padding-box;
		opacity: 0.5;
		filter: blur(50px);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 200px;
		@media(max-width: $md){
			height: 100px;
		}
		@media(max-width: $sm){
			height: 50px;
		}
	}
	#header-layer-bottom {
		background: transparent linear-gradient(180deg, #00000000 0%, #000000 100%) 0% 0% no-repeat padding-box;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 80px;
		opacity: 0.2;
		@media(max-width: $md){
			height: 40px;
		}
		@media(max-width: $sm){
			height: 20px;
		}
	}
	#primary-menu {
		position: relative;
		color: white;
		a {
			display: inline-block;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 20px;
			line-height: 24px;
			padding: 13px 0;
			margin: 0 25px;
			position: relative;
			@media(max-width: $menu){
				padding: 0 0 10px;
				margin: 0 10px;
				font-size: 15px;
			}
			@media(max-width: 1100px){
				max-width: 155px;
				vertical-align: top;
				text-align: center;
			}
			@media(max-width: 1050px){
				margin: 0 5px;
				font-size: 13px;
				line-height: 15px;
			}
			&:hover, &.active {
				&::after {
					content: " ";
					position: absolute;
					left: 0;
					bottom: 5px;
					border-bottom: 5px solid $red;
					width: 100%;
					@media(max-width: $menu){
						border-bottom-width: 3px;
					}
				}
			}
			&:last-child {
				border: 1px solid white;
				margin-top: 0;
				margin-bottom: 0;
				margin-right: 0;
				padding: 10px 25px;
				@media(max-width: $menu){
					padding: 5px 15px;
				}
				@media(max-width: 1100px){
					margin-top: -10px;
				}
				@media(max-width: 1050px){
					padding-top: 5px;
					padding-bottom: 5px;
					margin-top: -5px;
				}
				&:hover, &.active {
					&::after {
						border: 0;
					}
				}
			}
		}
	}
	.container-header {
		margin-left: 75px;
		margin-right: 60px;
		padding-top: 40px;
		padding-bottom: 40px;
		padding-right: 40px;
		position: relative;
		z-index: 99;
		@media(max-width: 1700px){
			margin-left: 40px;
			margin-right: 40px;
			padding-top: 25px;
			padding-bottom: 25px;
		}
		@media(max-width: 1200px){
			margin-left: 10px;
			margin-right: 10px;
		}
		@media(max-width: $menuH){
			display: none;
		}
		.container-header-top {
			display: flex;
			justify-content: space-between;
		}
		.submenu {
			display: none;
			padding-top: 20px;
			@media(max-width: $lg){
				padding-top: 5px;
			}
			@media(max-width: $md){
				padding-top: 5px;
			}
			a {
				font-weight: 400;
				padding: 0 25px;
				@media(max-width: $menu){
					font-size: 15px;
					padding: 0 10px;
				}
				@media(max-width: 1000px){
					font-size: 13px;
					line-height: 15px;
					padding: 0 5px;
				}
				&:hover, &.active {
					color: $red;
				}
			}
		}
		&:hover {
			background-color: white;
			box-shadow: 3px 3px 20px #00000029;
			#primary-menu {
				a {
					color: $gray;
					&:last-child {
						border: 1px solid $gray;
					}
				}
			}
		}
	}
	#submenu-indicaciones {
		width: 100%;
		padding: 40px 80px;
		display: none;
		#first-col-ind {
			a {
				display: block;
				padding-left: 10px;
				padding-right: 40px;
				white-space: nowrap;
				line-height: 60px;
				font-weight: 700;
				position: relative;
				@media(max-width: $md){
					line-height: 50px;
				}
				@media(max-width: $menu){
					font-size: 15px;
				}
				@media(max-width: 1000px){
					font-size: 13px;
					line-height: 15px;
				}
				span {
					position: absolute;
					right: 100%;
					width: 18px;
					text-align: center;
					padding: 3px 4px;
					height: 60px;
					transition: width 0.3s;
					@media(max-width: $md){
						height: 50px;
					}
					img {
						display: block;
						width: 0;
					}
				}
				&:hover, &.active {
					//background-color: #00A1E1;
					color: white;
					span {
						width: 62px;
						@media(max-width: $md){
							width: 50px;
						}
					}
					img {
						width: 54px;
						@media(max-width: $md){
							width: 40px;
						}
					}
				}
			}
		}
		#sec-col-ind {
			display: flex;
			width: 100%;
			.indication-submenu {
				//background-color: #00A1E1;
				padding: 10px;
				width: 340px;
				nav {
					display: none;
				}
			}
			a {
				display: block;
				color: white;
				padding: 10px;
				&:before {
					content: ' ';
					display: inline-block;
					background-image: url('../img/arrow_menu.svg');
					width: 18px;
					height: 18px;
					margin-right: 10px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
					vertical-align: top;
				}
				span {
					display: inline-block;
					width: calc(100% - 38px);
				}
				&:hover {
					font-weight: 700;
				}
			}
			.indication-thumb {
				background-size: cover;
				background-position: center;
				height: 100%;
				background-color: gray;
				flex: 1;
			}
		}
	}
}



#breadcrumbs {
	font-size: 14px;
	color: #55565A;
	margin: 20px 0 30px;
	@media(max-width: $breadcrumbs){
		display: none;
	}
	a {
		&:after {
			content: '>';
			font-weight: 900;
			margin: 0 10px;
		}
		&:last-child {
			color: $red;
			&:after {
				display: none;

			}
		}
	}
}

#hamburger {
	display: none;
	position: absolute;
	top: 25px;
	right: 25px;
	z-index: 99999;
	@media(max-width: $menuH){
		display: block;
	}
	@media(max-width: $sm){
		top: 10px;
		right: 10px;
	}
}

#menu-responsive {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: $red;
	color: white;
	padding: 100px 35px 35px;
	text-align: center;
	font-size: 20px;
	z-index: 9999;
	overflow: auto;
	a {
		display: block;
	}
	> nav > a {
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 40px;
		position: relative;
		&:last-child {
			margin-top: 50px;
			margin-bottom: 70px;
			&:before {
				content: ' ';
				border: 1px solid white;
				width: 120px;
				height: 50px;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%,-50%);
			}
		}
	}
	.submenu {
		display: none;
		a {
			margin-top: 10px;
			font-weight: 400;
		}
	}
}
#resp-search {
	margin-top: 50px;
	form {
		display: inline-block;
	}
	i {
		padding: 0 15px;
		cursor: pointer;
	}
	input {
		display: none;
		width: 0;
		border-radius: 0;
		border: 0;
		padding: 10px;
		font-size: 15px;
		width: calc(100% - 60px);
		margin: 0;
	}
}
#resp-social {
	margin-top: 25px;
	a {
		display: inline-block;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		line-height: 30px;
		text-align: center;
		font-size: 17px;
		border: 1px solid white;
		margin-right: 25px;
		i {
			vertical-align: text-top;
		}
	}
}
#resp-lang {
	display: inline-block;
	position: relative;
	img {
		cursor: pointer;
	}
	nav {
		display: none;
		background-color: white;
		top: 30px;
		left: 0;
		position: absolute;
		color: $red;
		font-size: 15px;
		padding: 5px 15px;
		z-index: 9;
		a {
			padding: 5px;
			text-align: left;
			border-left: 3px solid $red;
			&.active {
				border-left: 5px solid $red;
				font-weight: 700;
			}
		}
	}
}


#menu-responsive {
	a.button-white {
		width: 220px;
		margin: 20px auto;
		font-size: 20px;
		padding: 10px 15px;
	}
}
#resp-submenu-indicaciones {
	display: flex;
	margin-left: -35px;
	margin-right: -35px;
	.first-col-ind, .sec-col-ind {
		width: 50%;
		a {
			font-size: 15px;
			text-align: left;
		}
	}
	.first-col-ind {
		a {
			padding-top: 10px;
			padding-bottom: 10px;
			margin: 0;
			padding-left: 40px;
		}
	}
	.sec-col-ind {
		padding: 10px 20px;
	}
	.indication-submenu {
		nav {
			display: none;
		}
	}
}