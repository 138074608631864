.block-mission {
	margin-bottom: 50px;
	@media(max-width: $xs){
		margin-bottom: 25px;
	}
	&:nth-child(even){
		flex-direction: row-reverse;
		text-align: right;
	}
	.block-mission-img {
		@extend .thumb;
		padding-bottom: 80%;
		@media(max-width: $md){
			/*padding-bottom: 0;
			height: 200px;
			position: relative;*/
			//width: calc(100% + 50px);
		}
		@media(max-width: $xs){
			padding-bottom: 0;
			height: 110px;
			margin-bottom: 20px;
		}

	}
	&:nth-child(even){
		@media(max-width: $sm){
			.block-mission-img {
				right: -50px;
			}
		}
	}
	&:nth-child(odd){
		@media(max-width: $sm){
			.block-mission-img {
				left: -50px;
			}
		}
	}
	@media(max-width: $xs){
		h2 {
			text-align: center;
		}
	}
}

#mission-container-1 {
	@media(max-width: $xs){
		padding: 0;
		.block-mission {
			h2, .block-mission-img {
				max-width: 260px;
			}
			p {
				max-width: 300px;
			}
			&:nth-child(odd){
				p {
					margin-left: 36px;
				}
			}
			&:nth-child(even){
				text-align: right;
				p {
					margin-right: 36px;
				}
				h2, p, .block-mission-img {
					display: inline-block;
				}
				h2 {
					width: 100%;
				}
			}
		}
	}
}

#values-blocks {
	margin-bottom: 20px;
	justify-content: center;
	.block-value-wrap {
		margin: 0 10px 20px;
		max-width: 350px;
		@media(max-width: $md){
			max-width: 300px;
		}
		@media(max-width: $sm){
			width: calc(50% - 20px);
		}
		@media(max-width: $xs){
			max-width: none;
			width: 100%;
			margin: 0 0 20px;
		}
		&:before {
		    content:'';
		    float:left;
		    padding-top: 90%;
		    @media(max-width: $sm){
				padding-top: 60%;
			}
		    @media(max-width: $xs){
				padding-top:0;
			}
		}
	}

}
.block-value {
	padding: 40px 35px;
	box-shadow: 3px 3px 10px #00000029;
	height: 100%;
	@media(max-width: $md){
		padding: 30px 25px;
	}
	@media(max-width: $sm){
		padding: 20px;
	}
	h3 {
		text-align: center;
		@media(max-width: $xs){
			font-size: 17px;
			text-align: left;
		}
	}
	p {
		margin-bottom: 0;
	}
}
#mission-blocks {
	@media(max-width: $lg){
		padding: 0;
	}
}