#category-single, #category-indicacion, #producto-single {
	h1 {
		margin-bottom: 30px;
	}
}
#category-single {
	#category-menu {
		margin-top: 120px;
		@media(max-width: $lg){
			margin-top: 100px;
		}
		@media(max-width: $md){
			margin-top: 80px;
		}
		@media(max-width: $cat-sm){
			margin-top: 0;
		}
	}
	h1 {
		@media(max-width: $breadcrumbs){
			margin-top: 20px;
		}
		@media(max-width: $cat-sm){
			margin-top: 0;
		}
	}
}

#category-indicacion {
	h2 {
		font-size: 25px;
		line-height: 30px;
		margin: 30px 0 10px;
		@media(max-width: $md){
			font-size: 20px;
		}
		@media(max-width: $sm){
			font-size: 15px;
		}
	}
}

#category-products {
	margin-top: 70px;
	h2 {
		margin-bottom: 50px;
		font-size: 25px;
		line-height: 30px;
		@media(max-width: $md){
			margin-bottom: 30px;
			font-size: 20px;
		}
	}
	.related-product {
		margin-bottom: 40px;
		img {
			width: 100%;
			margin-bottom: 35px;
			@media(max-width: $md){
				margin-bottom: 20px;
			}
		}
	}
}