#innovacion {
	padding-bottom: 0;
	#general-content {
		p {
			font-weight: 700;
		}
	}
}
#innovation-blocks {
	margin-top: 100px;
	.block-stretch:last-child {
		margin-bottom: 0;
		p:last-child {
			padding-bottom: 40px;
		}
	}
	.stretch-content {
		img {
			margin-bottom: 25px;
		}
	}
	.stretch-thumb {
		@extend .thumb;
	}
}
