#quienes-somos {
	.highlight {
		font-weight: 500;
		font-size: 25px;
		line-height: 30px;
	}
	@media(max-width: $md){
		font-size: 18px;
		line-height: 25px;
		.highlight {
			font-size: 20px;
		}
	}
	@media(max-width: $sm){
		font-size: 15px;
		line-height: 24px;
		.highlight {
			font-size: 15px;
			line-height: 24px;
		}
	}
	p {
		//font-weight: 700;
		@media(max-width: $md){
			font-weight: normal;
		}
	}
	+ .video-block {
		&.on {
			height: 41vW;
			background-image: none;
			overflow: hidden;
			iframe {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}
}


#legal {
	h2 {
		margin-top: 80px;
		&:first-of-type {
			margin-top: 0;
		}
	}
}