footer {
	background-color: $red;
	padding: 60px 100px 30px;
	color: white;
	font-size: 20px;
	line-height: 23px;
	@media(max-width: $lg){
		font-size: 15px;
		line-height: 20px;
	}
	@media(max-width: $md){
		padding-top: 30px;
		padding-bottom: 15px;
	}
	@media(max-width: $sm){
		padding-left: 50px;
		padding-right: 50px;
		font-size: 20px;
		line-height: 23px;
	}
	@media(max-width: $xs){
		padding-left: 35px;
		padding-right: 35px;
		text-align: center;
	}
	.logo {
		height: 50px;
		margin-bottom: 60px;
		max-width: 100%;
		@media(max-width: $md){
			height: 30px;
			margin-bottom: 30px;
		}
		* {
			fill: #FFF !important;
		}
	}
	.footer-col {
		margin-bottom: 40px;
	}
	img {
		margin-bottom: 55px;
	}
	h4 {
		margin-bottom: 25px;
		@media(max-width: $md){
			font-size: 15px;
			line-height: 15px;
		}
		@media(max-width: $sm){
			margin-bottom: 35px;
			font-size: 20px;
			line-height: 20px;
			margin-bottom: 10px;
			text-transform: uppercase;
		}
	}
	a {
		display: block;
		margin-bottom: 3px;
	}
	#nav-legal {
		margin-top: 90px;
		text-align: center;
		@media(max-width: $md){
			margin-top: 0px;
		}
		a {
			display: inline-block;
			margin-right: 20px;
			padding-right: 20px;
			border-right: 1px solid white;
			&:last-child {
				margin-right: 0;
				padding-right: 0;
				border-right: 0;
			}
			@media(max-width: $xs){
				display: block;
				border: 0;
				padding: 0;
				margin: 0 0 10px;
			}
		}
	}
	#social-footer {
		margin-bottom: 40px;
		a {
			display: inline-block;
			border-radius: 50%;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 15px;
			border: 1px solid white;
			margin-right: 15px;
			i {
				vertical-align: text-top;
			}
		}
	}
}