.modal {
	.modal-content {
		padding: 30px 65px;
		border-radius: 0;
		border: 0;
	}
	.modal-footer {
		border: 0;
		padding: 0;
	}
	@media(max-width: $sm){
		padding: 0 !important;
		.modal-dialog {
			margin: 0;
			width: 100%; max-width: 100%;
			height: 100%; max-height: 100%;
			background-color: white;
		}
		.modal-footer {
			flex-wrap: nowrap;
			.button {
				min-width: 0;
				width: 50%;
			}
		}
	}
	.fa-times {
		position: absolute;
		top: 20px;
		right: 30px;
		cursor: pointer;
		font-size: 25px;
	}
}
[data-toggle="modal"]{
	cursor: pointer;
}