#contact-blocks {
	.block-stretch {
		margin-bottom: 80px;
		@media(max-width: $sm){
			margin-bottom: 40px;
		}
		.stretch-content {
			p {
				@media(max-width: $md){
					font-size: 18px;
					line-height: 25px;
				}
				@media(max-width: $sm){
					font-size: 15px;
					line-height: 20px;
				}
			}
			.button {
				margin-top: 50px;
				@media(max-width: $sm){
					margin-top: 0;
					display: block;
					width: 140px;
					margin-left: auto;
					margin-right: auto;
					margin-bottom: 10px;
				}
			}
		}
	}
} 
#contact-form {
	margin-bottom: 40px;
	form {
		margin-top: 90px;
		@media(max-width: $md){
			margin-top: 55px;
		}
		@media(max-width: $sm){
			margin-top: 40px;
		}
		p, label {
			font-size: 20px;
			line-height: 28px;
			@media(max-width: $md){
				font-size: 18px;
					line-height: 25px;
			}
			@media(max-width: $sm){
				font-size: 15px;
					line-height: 20px;
			}
		}
		.button {
			display: block;
			margin-top: 35px;
			@media(max-width: $sm){
				margin-top: 20px;
				display: block;
				width: 140px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

#contact-social {
	margin-top: 130px;
	@media(max-width: $md){
		margin-top: 75px;
	}
	#contact-social-wrap {
		display: flex;
		justify-content: space-between;
		margin-top: 75px;
		margin-bottom: 25px;
		@media(max-width: $md){
			margin-top: 55px;
		}
	}
	a {
		display: inline-block;
		box-shadow: 3px 3px 10px #00000029;
		color: $red;
		font-size: 30px;
		font-weight: 700;
		text-align: center;
		padding: 15px 25px;
		margin-right: 15px;
		@media(max-width: $lg){
			font-size: 25px;
		}
		i {
			display: inline-block;
			width: 60px;
			height: 60px;
			line-height: 60px;
			border: 2px solid $red;
			margin-right: 20px;
			border-radius: 50%;
			@media(max-width: $lg){
				width: 30px;
				height: 30px;
				line-height: 25px;
				font-size: 15px;
			}
		}
		&:hover {
			background-color: $red;
			color: white;
			i {
				border-color: white;
			}
		}
	}
}

#contacto {
	@media(max-width: $sm){
		h1,h2{
			text-align: center;
		}
	}
}

.select2-selection__arrow {
	background-image: url("../img/arrow_contact.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center top;
	@media(max-width: $sm){
		height: 20px !important;
		top: 19px !important;
		width: 20px !important;
	}
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border: 0;
	border-color: transparent !important;
}